import type { UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import { useMutation, useQueries, useQuery } from "@tanstack/react-query";

import type {
  UserSkill,
  Absence,
  Allocation,
  StaffingSuggestion,
  Project,
  ProjectAndProjectUser,
  ProjectWithProjectUsers,
  TeamWithUsers,
  Booking,
  User,
  UserDetails,
  UserAndProjectUser,
  DashboardResponse,
  SearchResults,
  SkillsWithCategories,
  ProjectUserRequest,
  SkillWithUsersAndSkills,
  Role,
  MyInfoResponse,
  ProjectTask,
  ProjectRole,
  TimeEntry,
  ProjectStats,
  Skill,
  SkillCategory,
  NewSkill,
  Holiday,
  Location,
  Tenant,
  UserWorkDaySchedule,
  ProjectTrackingSummary,
  ProjectUtilizationMap,
  DashboardProjectIssues,
  ProjectPlanSummary,
  ProjectPlanRecord,
  UserImbalance,
  ProjectBookingSummary,
  ClientId,
  ProjectUserStaffingSuggestion,
  StaffingSuggestionAndUserAndProject,
} from "./models";
import type { WorkbookData } from "./models/workbook";
import { date2string, formatDate } from "./utils";
import type { StaffingIssues } from "./models/reports/staffing_issues";
import type { TeamLeadReport } from "./routes/Reports/teamLeadTypes";
import type { ReportWeekOverview } from "./routes/Reports/types";
import { ProjectStar } from "./models/project_star";
import { Client } from "./models/client";

interface ParamOptionsType {
  start: string;
  end: string;
  user_id?: string;
  timestamp?: Date;
}
interface SetProjectPlanParams {
  projectId: string;
  records: ProjectPlanRecord[];
}

export const baseURL = import.meta.env.VITE_API_URL ?? "http://localhost:3000";

export class NotFoundError extends Error {}

interface ProjectUserRequestParams {
  user_id?: number;
  project_id?: number;
}

export interface ApiClient {
  fetchProjectUserRequests: (
    params?: ProjectUserRequestParams,
  ) => UseQueryResult<Array<ProjectUserRequest>>;
  fetchProjectUserRequestById: (
    purId: string,
  ) => UseQueryResult<ProjectUserRequest>;
  fetchInactiveProjectTrackingSummaries: () => UseQueryResult<
    Array<ProjectTrackingSummary>
  >;

  setProjectPlan: () => UseMutationResult<
    ProjectPlanRecord,
    Error,
    SetProjectPlanParams,
    unknown
  >;

  createProjectUserRequest: () => UseMutationResult<
    ProjectUserRequest,
    Error,
    ProjectUserRequest,
    unknown
  >;
  setAllocation(a: Allocation): Promise<Allocation>;
  updateProjectUserRequest: () => UseMutationResult<
    ProjectUserRequest,
    Error,
    ProjectUserRequest,
    unknown
  >;
  acceptProjectUserRequest: () => UseMutationResult<
    ProjectUserRequest,
    Error,
    ProjectUserRequest,
    unknown
  >;
  rejectProjectUserRequest: () => UseMutationResult<
    ProjectUserRequest,
    Error,
    ProjectUserRequest,
    unknown
  >;

  fetchWorkbook: (d: Date) => UseQueryResult<WorkbookData>;
  fetchReportStaffingIssues: (d: Date) => UseQueryResult<StaffingIssues>;

  fetchTenants: () => UseQueryResult<Array<Tenant>>;
  fetchTenantById: (tenantId: string) => UseQueryResult<Tenant>;
  fetchUsersByTenantId: (tenantId: string) => UseQueryResult<Array<User>>;

  fetchProjects: () => UseQueryResult<Array<Project>>;
  fetchProjectUtilization: (
    options: ParamOptionsType,
  ) => UseQueryResult<ProjectUtilizationMap>;

  fetchOpenStaffingSuggestions: () => UseQueryResult<
    StaffingSuggestionAndUserAndProject[]
  >;
  fetchProjectById: (
    projectId: string,
  ) => UseQueryResult<ProjectWithProjectUsers>;
  fetchProjectsByIds: (
    projectIds: Array<string>,
  ) => Array<UseQueryResult<Project>>;

  fetchProjectBookingSummaryByProjectId: (
    projectId: string,
  ) => UseQueryResult<ProjectBookingSummary>;

  fetchProjectBookingsByProjectId: (
    projectId: string,
    options: ParamOptionsType,
  ) => UseQueryResult<Array<Booking>>;
  fetchProjectBookingsByUserId: (
    userId: string,
    options: ParamOptionsType,
  ) => UseQueryResult<Array<Booking>>;
  fetchProjectBookingsByUserIds: (
    userIds: Array<string>,
    options: any,
  ) => Array<UseQueryResult<Array<Booking>>>;
  fetchTimeEntriesByUserIds: (
    userIds: Array<string>,
    options: any,
  ) => Array<UseQueryResult<Array<TimeEntry>>>;

  fetchTeams: () => UseQueryResult<Array<TeamWithUsers>>;
  fetchTeamById: (teamId: string) => UseQueryResult<TeamWithUsers>;
  fetchLocations: () => UseQueryResult<Array<Location>>;
  fetchLocationById: (locationId: string) => UseQueryResult<Location>;

  fetchUsers: () => UseQueryResult<Array<User>>;
  fetchClients: () => UseQueryResult<Array<Client>>;
  fetchClientById: (clientId: ClientId) => UseQueryResult<Client>;
  fetchUserImbalance: () => UseQueryResult<Array<UserImbalance>>;
  fetchIgnoredUsers: () => UseQueryResult<Array<User>>;
  fetchUserById: (userId: string) => UseQueryResult<User>;
  fetchUsersByIds: (userIds: Array<string>) => Array<UseQueryResult<User>>;
  fetchUserDetailsById: (userId: string) => UseQueryResult<UserDetails>;
  fetchOverbookedUsers: (options: any) => UseQueryResult<Array<User>>;
  fetchUnderbookedUsers: (options: any) => UseQueryResult<Array<User>>;
  fetchDirectReportsByUserId: (userId: string) => UseQueryResult<Array<User>>;

  fetchMyProjectStars: () => UseQueryResult<Array<ProjectStar>>;
  useCreateProjectStar: (
    projectId: number,
  ) => UseMutationResult<ProjectStar, Error, number>;
  useDeleteProjectStar: (
    projectStarId: number,
  ) => UseMutationResult<ProjectStar, Error, number>;

  fetchReportWeekOverview: (
    userId: string,
    date: Date,
  ) => UseQueryResult<ReportWeekOverview>;
  fetchReportTeamLead: (
    userId: number,
    options: ParamOptionsType,
  ) => UseQueryResult<TeamLeadReport>;

  fetchUserWorkDayScheduleByUserId: (
    userId: string,
  ) => UseQueryResult<Array<UserWorkDaySchedule>>;
  fetchUserWorkDaySchedulesByUserIds: (
    userId: Array<string>,
    options?: ParamOptionsType,
  ) => Array<UseQueryResult<Array<UserWorkDaySchedule>>>;

  fetchProjectRolesByProjectId: (
    projectId: string,
  ) => UseQueryResult<Array<ProjectRole>>;
  fetchProjectTasksByProjectId: (
    projectId: string,
  ) => UseQueryResult<Array<ProjectTask>>;
  fetchProjectRolesByProjectIds: (
    projectIds: Array<string>,
  ) => Array<UseQueryResult<Array<ProjectRole>>>;
  fetchTimeEntriesByProjectId: (
    projectId: string,
    options?: ParamOptionsType,
  ) => UseQueryResult<Array<TimeEntry>>;
  fetchTimeEntriesByTeamId: (
    teamId: string,
    options: ParamOptionsType,
  ) => UseQueryResult<Array<TimeEntry>>;
  fetchTimeEntriesByUserId: (
    userId: string,
    options: ParamOptionsType,
  ) => UseQueryResult<Array<TimeEntry>>;

  fetchHolidaysByLocationId: (
    locationId: number | undefined,
    options: ParamOptionsType,
  ) => UseQueryResult<Array<Holiday>>;

  fetchHolidaysByLocationIds: (
    locationId: Array<number>,
    options: ParamOptionsType,
  ) => Array<UseQueryResult<Array<Holiday>>>;

  updateUser: () => UseMutationResult<User, Error, User, unknown>;

  fetchRoles: () => UseQueryResult<Array<Role>>;

  createStaffingSuggestion: (
    callback: () => void,
  ) => UseMutationResult<
    StaffingSuggestion,
    Error,
    StaffingSuggestion,
    unknown
  >;
  fetchStaffingSuggestionByProjectId: (
    projectId: string,
  ) => UseQueryResult<Array<StaffingSuggestion>>;
  fetchAllStaffingSuggestionByProjectId: (
    projectId: string,
  ) => UseQueryResult<Array<StaffingSuggestion>>;
  fetchProjectStatsByProjectId: (
    projectId: string,
  ) => UseQueryResult<ProjectStats>;
  fetchProjectPlanByProjectId: (
    projectId: string,
  ) => UseQueryResult<ProjectPlanSummary>;
  fetchClientPlanByClientId: (
    clientId: string,
  ) => UseQueryResult<ProjectPlanSummary>;

  fetchProjectUserStaffingSuggestionByUserId: (
    userId: string,
  ) => UseQueryResult<Array<ProjectUserStaffingSuggestion>>;
  fetchMyProjects: () => UseQueryResult<Array<Project>>;
  fetchMyManagedProjects: () => UseQueryResult<Array<Project>>;
  fetchMyInfo: () => UseQueryResult<MyInfoResponse>;
  fetchMyDashboardInfo: () => UseQueryResult<DashboardResponse>;
  fetchDashboardProjectIssues: () => UseQueryResult<DashboardProjectIssues>;

  fetchProjectUsersByUserId: (
    userId: string,
  ) => UseQueryResult<Array<ProjectAndProjectUser>>;
  fetchHistoricalStaffingByUserId: (
    userId: string,
  ) => UseQueryResult<Array<Booking>>;

  useSetAllocation: (
    projectUserId: string,
    date: string,
  ) => UseMutationResult<Allocation, Error, Allocation>;
  createProjectUser: (
    userId: string,
    projectId: string,
  ) => Promise<UserAndProjectUser>;
  removeProjectUser: (projectUserId: string) => Promise<void>;

  search: (q: string) => Promise<SearchResults>;
  acceptStaffingSuggestion: (
    staffingRequestId: string,
  ) => Promise<SearchResults>;
  rejectStaffingSuggestion: (
    staffingRequestId: string,
  ) => Promise<SearchResults>;

  fetchAbsencesByUserIds: (
    userId: Array<string>,
    options: ParamOptionsType,
  ) => Array<UseQueryResult<Array<Absence>>>;

  fetchAbsencesByUserId: (
    userId: string,
    options: ParamOptionsType,
  ) => UseQueryResult<Array<Absence>>;

  createSkill: () => UseMutationResult<Skill, Error, NewSkill, unknown>;
  updateSkill: () => UseMutationResult<Skill, Error, Skill, unknown>;
  deleteSkill: (skillId: string) => Promise<void>;

  fetchSkillCategories: () => UseQueryResult<Array<SkillCategory>>;
  fetchSkills: () => UseQueryResult<SkillsWithCategories>;
  fetchSkillById: (skillId: string) => UseQueryResult<SkillWithUsersAndSkills>;

  fetchUserSkills: () => UseQueryResult<Array<UserSkill>>;
  upsertUserSkill: () => UseMutationResult<
    UserSkill,
    Error,
    UserSkill,
    unknown
  >;
  fetchUserSkillsByUserId: (id: string) => UseQueryResult<Array<UserSkill>>;
}

const defaultStaleTime = 1000 * 60 * 1;
const defaultCacheTime = 1000 * 60 * 5;

export function Api(token: string): ApiClient {
  function rawFetchBasicProjectById(projectId: string): Promise<any> {
    return f(`/projects/${projectId}`);
  }

  function rawFetchProjectById(projectId: string): Promise<any> {
    return f(`/projects/${projectId}/details`);
  }

  function rawFetchProjectBookingsByUserId(
    userId: string,
    options = {} as ParamOptionsType,
  ) {
    options.user_id = userId;
    const params = param2query(options);
    return f(`/project_bookings?${params}`);
  }

  function fetchReportTeamLead(userId: number, options: ParamOptionsType) {
    const startKey = options.start ?? "";
    const endKey = options.end ?? "";
    options.user_id = userId.toString();
    const params = param2query(options);

    return useQuery({
      queryKey: ["report", "team_lead", userId, startKey, endKey],
      queryFn: () => f(`/reports/team_lead?${params}`),
      staleTime: defaultStaleTime,
      gcTime: defaultCacheTime,
    });
  }

  function fetchProjectById(projectId: string) {
    return useQuery({
      queryKey: ["project", projectId, "detailed"],
      queryFn: () => rawFetchProjectById(projectId),
      staleTime: defaultStaleTime,
      gcTime: defaultCacheTime,
    });
  }

  function fetchProjectsByIds(projectIds: Array<string>) {
    const queries = projectIds.map((projectId) => {
      return {
        queryKey: ["project", projectId],
        queryFn: () => rawFetchBasicProjectById(projectId),
        staleTime: defaultStaleTime,
        gcTime: defaultCacheTime,
      };
    });

    return useQueries({ queries: queries });
  }

  function fetchMyProjectStars() {
    return useQuery({
      queryKey: ["project-stars"],
      queryFn: () => f("/project_stars"),
    });
  }

  function fetchWorkbook(d: Date) {
    const dt = date2string(d);
    return useQuery({
      queryKey: ["workbook", dt],
      queryFn: () => f(`/workbooks/${dt}`),
    });
  }

  function fetchDashboardProjectIssues() {
    return useQuery({
      queryKey: ["dashboard", "project_issues"],
      queryFn: () => f(`/dashboard/project_issues`),
    });
  }

  function fetchOpenStaffingSuggestions() {
    return useQuery({
      queryKey: ["staffing_suggestions"],
      queryFn: () => f(`/staffing_suggestions?state=new`),

    });
  }

  function fetchReportStaffingIssues(d: Date) {
    const dt = date2string(d);

    return useQuery({
      queryKey: ["reports", "staffing_issues", dt],
      queryFn: () => f(`/reports/staffing_issues?date=${dt}`),
    });
  }

  function fetchUnderbookedUsers(options: any) {
    let params = param2query(options);
    return useQuery({
      queryKey: ["users", "underbooked"],
      queryFn: () =>
        f(`/users/overbooked?${params}&method=underbooked&threshold=10`),
    });
  }

  function fetchProjectUtilization(options: ParamOptionsType) {
    const params = param2query(options);
    const startKey = options.start ?? "";
    const endKey = options.end ?? "";

    return useQuery({
      queryKey: ["project", "utilization", startKey, endKey],
      queryFn: () => f(`/projects/utilization?${params}`),
    });
  }

  function fetchOverbookedUsers(options: any) {
    let params = param2query(options);
    return useQuery({
      queryKey: ["users", "overbooked"],
      queryFn: () =>
        f(`/users/overbooked?${params}&method=overbooked&threshold=41`),
    });
  }

  function fetchProjectUserRequests(options?: ProjectUserRequestParams) {
    let params = param2query(options || {});

    return useQuery({
      queryKey: ["project_user_requests"],
      queryFn: () => f(`/project_user_requests?${params}`),
    });
  }

  function fetchProjectUserRequestById(projectUserRequestId: string) {
    return useQuery({
      queryKey: ["project_user_requests", projectUserRequestId],
      queryFn: () => f("/project_user_requests/" + projectUserRequestId),
    });
  }
  function fetchInactiveProjectTrackingSummaries() {
    return useQuery({
      queryKey: ["project", "inactive"],
      queryFn: () => f("/projects/inactive"),
    });
  }

  function createProjectUserRequest() {
    return useMutation<ProjectUserRequest, Error, ProjectUserRequest, unknown>({
      mutationFn: (pur: ProjectUserRequest) =>
        f("/project_user_requests", {
          body: JSON.stringify(pur),
          method: "POST",
        }),
    });
  }

  function setProjectPlan() {
    return useMutation<ProjectPlanRecord, Error, SetProjectPlanParams, unknown>(
      {
        mutationFn: ({ projectId, records }: SetProjectPlanParams) => {
          return f(`/projects/${projectId}/plan`, {
            body: JSON.stringify({ plan: records }),
            method: "POST",
          });
        },
      },
    );
  }

  function updateProjectUserRequest() {
    return useMutation<ProjectUserRequest, Error, ProjectUserRequest, unknown>({
      mutationFn: (pur: ProjectUserRequest) =>
        f("/project_user_requests/" + pur.id, {
          body: JSON.stringify(pur),
          method: "PATCH",
        }),
    });
  }

  function acceptProjectUserRequest() {
    return useMutation<ProjectUserRequest, Error, ProjectUserRequest, unknown>({
      mutationFn: (pur: ProjectUserRequest) =>
        f(`/project_user_requests/${pur.id}/accept?user_id=${pur.user_id}`, {
          method: "POST",
        }),
    });
  }

  function rejectProjectUserRequest() {
    return useMutation<ProjectUserRequest, Error, ProjectUserRequest, unknown>({
      mutationFn: (pur: ProjectUserRequest) =>
        f(`/project_user_requests/${pur.id}/reject?reason=${pur.reason}`, {
          method: "POST",
        }),
    });
  }

  function fetchRoles() {
    return useQuery({
      queryKey: ["roles"],
      queryFn: () => f("/roles"),
    });
  }

  function fetchTenants() {
    return useQuery({
      queryKey: ["tenants"],
      queryFn: () => f("/tenants"),
    });
  }

  function fetchTenantById(tenantId: string) {
    return useQuery({
      queryKey: ["tenant", tenantId, "detailed"],
      queryFn: () => f(`/tenants/${tenantId}`),
    });
  }

  function fetchProjects() {
    return useQuery({
      queryKey: ["projects"],
      queryFn: () => f("/projects"),
    });
  }

  function fetchProjectBookingsByProjectId(
    projectId: string,
    options: ParamOptionsType,
  ) {
    const params = param2query(options);
    const startKey = options.start ?? "";
    const endKey = options.end ?? "";
    return useQuery({
      queryKey: ["projects", projectId, "project_bookings", startKey, endKey],
      queryFn: () => f(`/projects/${projectId}/project_bookings?${params}`),
    });
  }

  function fetchProjectBookingSummaryByProjectId(projectId: string) {
    return useQuery({
      queryKey: ["projects", projectId, "booking_summary"],
      queryFn: () => f(`/projects/${projectId}/bookings`),
    });
  }

  function fetchProjectBookingsByUserId(
    userId: string,
    options: ParamOptionsType,
  ) {
    const startKey = options.start ?? "";
    const endKey = options.end ?? "";
    const timestamp = options.timestamp
      ? options.timestamp.getTime().toString()
      : "";

    return useQuery({
      queryKey: [
        "users",
        userId,
        "project_bookings",
        startKey,
        endKey,
        timestamp,
      ],
      queryFn: () => rawFetchProjectBookingsByUserId(userId, options),
    });
  }

  function fetchProjectBookingsByUserIds(
    userIds: Array<string>,
    options: ParamOptionsType,
  ) {
    const startKey = options.start ?? "";
    const endKey = options.end ?? "";
    const queries = userIds.map((uId) => {
      return {
        queryKey: ["users", uId, "project_bookings", startKey, endKey],
        queryFn: () => rawFetchProjectBookingsByUserId(uId, options),
      };
    });

    return useQueries({ queries: queries });
  }

  function fetchAbsencesByUserIds(
    userIds: Array<string>,
    options: ParamOptionsType,
  ) {
    const params = param2query(options);
    const startKey = options.start ?? "";
    const endKey = options.end ?? "";
    const queries = userIds.map((uId) => {
      return {
        queryKey: ["absences", uId, startKey, endKey],
        queryFn: () => f("/absences/search?user_id=" + uId + "&" + params),
      };
    });

    return useQueries({ queries: queries });
  }

  function fetchLocations() {
    return useQuery({
      queryKey: ["locations"],
      queryFn: () => f("/locations"),
    });
  }

  function fetchLocationById(locationId: string) {
    return useQuery({
      queryKey: ["locations", locationId],
      queryFn: () => f(`/locations/${locationId}`),
    });
  }

  function fetchTeams() {
    return useQuery({
      queryKey: ["teams"],
      queryFn: () => f("/teams"),
    });
  }

  function fetchTeamById(teamId: string) {
    return useQuery({
      queryKey: ["teams", teamId],
      queryFn: () => f("/teams/" + teamId),
    });
  }

  function fetchUserDetailsById(userId: string) {
    return useQuery({
      queryKey: ["users", userId, "details"],
      queryFn: () => f(`/users/${userId}/details`),
    });
  }

  function fetchUserById(userId: string) {
    return useQuery({
      queryKey: ["users", userId],
      queryFn: () => f(`/users/${userId}`),
      enabled: !!userId,
    });
  }

  function fetchClientById(clientId: ClientId) {
    return useQuery({
      queryKey: ["clients", clientId],
      queryFn: () => f(`/clients/${clientId}`),
      enabled: !!clientId,
    });
  }

  function fetchIgnoredUsers() {
    return useQuery({
      queryKey: ["users", "ignored"],
      queryFn: () => f("/users/ignored"),
    });
  }

  function fetchClients() {
    return useQuery({
      queryKey: ["clients"],
      queryFn: () => f("/clients"),
    });
  }

  function fetchUsers() {
    return useQuery({
      queryKey: ["users"],
      queryFn: () => f("/users"),
    });
  }

  function fetchUserImbalance() {
    return useQuery({
      queryKey: ["users", "imbalance"],
      queryFn: () => f("/users/imbalance"),
    });
  }

  function fetchUsersByTenantId(tenantId: string) {
    return useQuery({
      queryKey: ["tenants", tenantId, "users"],
      queryFn: () => f("/tenants/" + tenantId + "/users"),
    });
  }

  function fetchProjectUsersByUserId(userId: string) {
    return useQuery({
      queryKey: ["users", userId, "project_users"],
      queryFn: () => f("/users/" + userId + "/project_users"),
    });
  }

  function createProjectUser(userId: string, projectId: string) {
    const data = {
      project_id: projectId,
      user_id: userId,
    };

    return f("/project_users", {
      body: JSON.stringify(data),
      method: "POST",
    });
  }

  function removeProjectUser(projectUserId: string) {
    return f("/project_users/" + projectUserId, {
      method: "DELETE",
    });
  }

  function useCreateProjectStar(projectId: number) {
    return useMutation<ProjectStar, Error, number>({
      mutationKey: ["project_star", "create", projectId],
      retry: 3,
      onError: (error) => {
        console.error("Mutation failed:", error.message);
      },
      mutationFn: (projectId: number) => {
        return f(`/project_stars/${projectId}`, {
          body: JSON.stringify({ projectId: projectId }),
          method: "DELETE",
        });
      },
    });
  }

  function useDeleteProjectStar(projectStarId: number) {
    return useMutation<ProjectStar, Error, number>({
      mutationKey: ["project_star", "delete", projectStarId],
      retry: 3,
      onError: (error) => {
        console.error("Mutation failed:", error.message);
      },
      mutationFn: (projectStarId: number) => {
        return f(`/project_stars/${projectStarId}`, {
          method: "DELETE",
        });
      },
    });
  }

  function setAllocation(a: Allocation) {
    const data = {
      project_user_id: a.project_user_id,
      date: a.date,
      amount: a.amount,
      kind: a.kind,
    };

    return f("/project_bookings", {
      body: JSON.stringify(data),
      method: "POST",
    });
  }

  function useSetAllocation(projectUserId: string, date: string) {
    return useMutation<Allocation, Error, Allocation>({
      mutationKey: ["project_user", projectUserId, "date", date],
      retry: 3,
      onError: (error) => {
        console.error("Mutation failed:", error.message);
      },
      mutationFn: (newAllocation: Allocation) => {
        return setAllocation(newAllocation);
      },
    });
  }

  function upsertUserSkill() {
    return useMutation<UserSkill, Error, UserSkill, unknown>({
      mutationFn: (user_skill: UserSkill) =>
        f("/user_skills", {
          body: JSON.stringify(user_skill),
          method: "POST",
        }),
    });
  }

  function updateUser() {
    return useMutation<User, Error, User, unknown>({
      mutationFn: (user: User) =>
        f("/users/" + user.id, {
          body: JSON.stringify(user),
          method: "PATCH",
        }),
    });
  }

  function createStaffingSuggestion(callback: () => void) {
    return useMutation<StaffingSuggestion, Error, StaffingSuggestion, unknown>({
      mutationFn: (sr: StaffingSuggestion) =>
        f("/staffing_suggestions", {
          body: JSON.stringify(sr),
          method: "POST",
        }),
      onSuccess: callback,
    });
  }

  function fetchClientPlanByClientId(clientId: string) {
    return useQuery({
      queryKey: ["client", clientId, "planning_summary"],
      queryFn: () => f("/clients/" + clientId + "/planning_summary"),
    });
  }

  function fetchProjectPlanByProjectId(projectId: string) {
    return useQuery({
      queryKey: ["project", projectId, "planning_summary"],
      queryFn: () => f("/projects/" + projectId + "/planning_summary"),
    });
  }

  function fetchProjectStatsByProjectId(projectId: string) {
    return useQuery({
      queryKey: ["project", projectId, "stats"],
      queryFn: () => f("/projects/" + projectId + "/stats"),
    });
  }

  function fetchAllStaffingSuggestionByProjectId(projectId: string) {
    return useQuery({
      queryKey: ["project", projectId, "staffing_suggestions", "all"],
      queryFn: () =>
        f("/projects/" + projectId + "/staffing_suggestions?all=true"),
      staleTime: 0,
      gcTime: 0,
    });
  }

  function fetchStaffingSuggestionByProjectId(projectId: string) {
    return useQuery({
      queryKey: ["project", projectId, "staffing_suggestions"],
      queryFn: () => f("/projects/" + projectId + "/staffing_suggestions"),
      staleTime: 0,
      gcTime: 0,
    });
  }

  function fetchProjectUserStaffingSuggestionByUserId(userId: string) {
    return useQuery({
      queryKey: ["users", userId, "staffing_suggestions"],
      queryFn: () => f("/users/" + userId + "/staffing_suggestions"),
    });
  }

  function fetchReportWeekOverview(userId: string, date: Date) {
    return useQuery({
      queryKey: ["user", userId, "schedule", date],
      queryFn: () =>
        fetchWithoutAuth(
          `/reports/week_overview?user_id=${userId}&date=${formatDate(date)}`,
        ).then((x: ReportWeekOverview) => {
          return x;
        }),
    });
  }

  function fetchUserWorkDayScheduleByUserId(userId: string) {
    return useQuery({
      queryKey: ["user", userId, "schedule"],
      queryFn: () => f("/users/" + userId + "/schedule"),
    });
  }

  function fetchUserWorkDaySchedulesByUserIds(userIds: Array<string>) {
    const queries = userIds.map((userId) => {
      return {
        queryKey: ["user", userId, "schedule"],
        queryFn: () => f(`/users/${userId}/schedule`),
      };
    });

    return useQueries({ queries: userIds ? queries : [] });
  }

  function fetchDirectReportsByUserId(userId: string) {
    return useQuery({
      queryKey: ["user", userId, "direct_reports"],
      queryFn: () => f("/users/" + userId + "/direct_reports"),
    });
  }

  function fetchUsersByIds(userIds: Array<string>) {
    const queries = userIds.map((userId) => {
      return {
        queryKey: ["user", userId],
        queryFn: () => f("/users/" + userId),
      };
    });

    return useQueries({ queries: userIds ? queries : [] });
  }

  function fetchTimeEntriesByTeamId(teamId: string, options: ParamOptionsType) {
    const params = param2query(options);
    const startKey = options.start ?? "";
    const endKey = options.end ?? "";
    return useQuery({
      queryKey: ["team", teamId, "time_entries", startKey, endKey],
      queryFn: () => f("/teams/" + teamId + "/time_entries?" + params),
    });
  }

  function fetchTimeEntriesByUserIds(
    userIds: Array<string>,
    options: ParamOptionsType,
  ) {
    const params = param2query(options);
    const startKey = options.start ?? "";
    const endKey = options.end ?? "";

    const queries = userIds.map((userId) => {
      return {
        queryKey: ["user", userId, "time_entries", startKey, endKey],
        queryFn: () => f("/users/" + userId + "/time_entries?" + params),
      };
    });

    return useQueries({ queries: queries });
  }

  function fetchHolidaysByLocationIds(
    locationIds: Array<number>,
    options: ParamOptionsType,
  ) {
    const params = param2query(options);
    const startKey = options.start ?? "";
    const endKey = options.end ?? "";

    const queries = locationIds.map((locationId) => {
      return {
        queryKey: ["location", locationId, "holidays", startKey, endKey],
        queryFn: () => f("/locations/" + locationId + "/holidays?" + params),
      };
    });

    return useQueries({ queries: queries });
  }

  function fetchHolidaysByLocationId(
    locationId: number | undefined,
    options: ParamOptionsType,
  ) {
    const params = param2query(options);
    const startKey = options.start ?? "";
    const endKey = options.end ?? "";

    return useQuery({
      queryKey: ["location", locationId, "holidays", startKey, endKey],
      enabled: !!locationId,
      queryFn: () =>
        f("/locations/" + locationId?.toString() + "/holidays?" + params),
    });
  }

  function fetchTimeEntriesByUserId(userId: string, options: ParamOptionsType) {
    const params = param2query(options);
    const startKey = options.start ?? "";
    const endKey = options.end ?? "";

    return useQuery({
      queryKey: ["user", userId, "time_entries", startKey, endKey],
      queryFn: () => f("/users/" + userId + "/time_entries?" + params),
    });
  }

  function fetchTimeEntriesByProjectId(
    projectId: string,
    options?: ParamOptionsType,
  ) {
    if (options) {
      const params = param2query(options);
      const startKey = options.start ?? "";
      const endKey = options.end ?? "";
      return useQuery({
        queryKey: ["project", projectId, "time_entries", startKey, endKey],
        queryFn: () => f("/projects/" + projectId + "/time_entries?" + params),
      });
    }
    return useQuery({
      queryKey: ["project", projectId, "time_entries"],
      queryFn: () => f("/projects/" + projectId + "/time_entries"),
    });
  }

  function fetchProjectRolesByProjectId(projectId: string) {
    return useQuery({
      queryKey: ["project", projectId, "project_roles"],
      queryFn: () => f("/projects/" + projectId + "/project_roles"),
    });
  }

  function fetchProjectTasksByProjectId(projectId: string) {
    return useQuery({
      queryKey: ["project", projectId, "project_tasks"],
      queryFn: () => f("/projects/" + projectId + "/project_tasks"),
    });
  }

  function fetchProjectRolesByProjectIds(projectIds: Array<string>) {
    const queries = projectIds.map((projectId) => {
      return {
        queryKey: ["project", projectId, "project_roles"],
        queryFn: () => f("/projects/" + projectId + "/project_roles"),
      };
    });

    return useQueries({ queries: queries });
  }

  function fetchHistoricalStaffingByUserId(userId: string) {
    return useQuery({
      queryKey: ["user", userId, "historical"],
      queryFn: () => f("/users/" + userId + "/historical"),
    });
  }

  function fetchAbsencesByUserId(userId: string, options: ParamOptionsType) {
    const params = param2query(options);
    const startKey = options.start ?? "";
    const endKey = options.end ?? "";
    return useQuery({
      queryKey: ["absences", userId, startKey, endKey],
      queryFn: () => f("/absences/search?user_id=" + userId + "&" + params),
    });
  }

  function fetchUserSkillsByUserId(userId: string) {
    return useQuery({
      queryKey: ["users", userId, "user_skills"],
      queryFn: () => f(`/users/${userId}/user_skills`),
    });
  }

  function fetchUserSkills() {
    return useQuery({
      queryKey: ["user_skills"],
      queryFn: () => f("/user_skills"),
    });
  }

  function createSkill() {
    return useMutation<Skill, Error, NewSkill, unknown>({
      mutationFn: (sk: NewSkill) =>
        f("/skills", {
          body: JSON.stringify(sk),
          method: "POST",
        }),
    });
  }

  function updateSkill() {
    return useMutation<Skill, Error, Skill, unknown>({
      mutationFn: (sk: Skill) =>
        f(`/skills/${sk.id}`, {
          body: JSON.stringify(sk),
          method: "PATCH",
        }),
    });
  }

  function deleteSkill(skillId: string) {
    return f(`/skills/${skillId}`, {
      method: "DELETE",
    });
  }

  function fetchSkillCategories() {
    return useQuery({
      queryKey: ["skill_categories"],
      queryFn: () => f("/skill_categories"),
    });
  }

  function fetchSkills() {
    return useQuery({
      queryKey: ["skills"],
      queryFn: () => f("/skills"),
    });
  }

  function fetchSkillById(skillId: string) {
    return useQuery({
      queryKey: ["skills", skillId],
      queryFn: () => f(`/skills/${skillId}`),
    });
  }

  function fetchMyInfo() {
    return useQuery({
      queryKey: ["dashboard", "my_info"],
      queryFn: () => f("/dashboard/my_info"),
      enabled: !!token,
    });
  }

  function fetchMyDashboardInfo() {
    return useQuery({
      queryKey: ["dashboard", "index"],
      queryFn: () => f("/dashboard"),
    });
  }

  function fetchMyManagedProjects() {
    return useQuery({
      queryKey: ["projects", "managed"],
      queryFn: () => f("/projects/managed"),
    });
  }

  function fetchMyProjects() {
    return useQuery({
      queryKey: ["projects", "mine"],
      queryFn: () => f("/projects/mine"),
    });
  }

  function rejectStaffingSuggestion(id: string) {
    return f(`/staffing_suggestions/${id}/reject`, {
      method: "POST",
    });
  }

  function acceptStaffingSuggestion(id: string) {
    return f(`/staffing_suggestions/${id}/accept`, {
      method: "POST",
    });
  }

  function search(query: string) {
    return f(`/search?q=${query}`);
  }

  function f(path: string, opts = {}) {
    return fetchWithAuth(token, path, opts);
  }

  return {
    createProjectUserRequest,
    fetchProjectUserRequests,
    fetchProjectUserRequestById,
    updateProjectUserRequest,
    acceptProjectUserRequest,
    rejectProjectUserRequest,

    fetchProjects,
    fetchProjectUtilization,
    fetchProjectById,
    fetchProjectsByIds,
    fetchProjectBookingsByUserIds,
    fetchProjectBookingsByUserId,
    fetchProjectBookingsByProjectId,
    fetchProjectBookingSummaryByProjectId,
    fetchTeams,
    fetchTeamById,
    fetchLocations,
    fetchLocationById,
    fetchUsers,
    fetchClients,
    fetchClientById,
    fetchUserImbalance,
    fetchIgnoredUsers,
    fetchUserById,
    fetchUsersByIds,
    fetchUserDetailsById,
    updateUser,
    fetchProjectUsersByUserId,
    fetchOpenStaffingSuggestions,

    fetchMyInfo,
    fetchMyDashboardInfo,
    fetchMyManagedProjects,
    fetchMyProjects,
    fetchDirectReportsByUserId,
    fetchHistoricalStaffingByUserId,
    fetchOverbookedUsers,
    fetchUnderbookedUsers,

    fetchProjectTasksByProjectId,
    fetchProjectRolesByProjectIds,
    fetchProjectRolesByProjectId,
    fetchTimeEntriesByProjectId,
    fetchTimeEntriesByTeamId,
    fetchTimeEntriesByUserId,
    fetchTimeEntriesByUserIds,
    fetchHolidaysByLocationId,
    fetchHolidaysByLocationIds,

    createProjectUser,
    removeProjectUser,
    createStaffingSuggestion,
    acceptStaffingSuggestion,
    rejectStaffingSuggestion,
    fetchStaffingSuggestionByProjectId,
    fetchAllStaffingSuggestionByProjectId,
    fetchProjectStatsByProjectId,
    fetchProjectPlanByProjectId,
    fetchClientPlanByClientId,
    fetchProjectUserStaffingSuggestionByUserId,
    useSetAllocation,
    search,
    fetchAbsencesByUserId,
    fetchAbsencesByUserIds,

    createSkill,
    updateSkill,
    deleteSkill,

    fetchSkillCategories,
    fetchSkills,
    fetchSkillById,
    fetchUserSkills,
    upsertUserSkill,
    fetchUserSkillsByUserId,

    fetchUserWorkDayScheduleByUserId,
    fetchUserWorkDaySchedulesByUserIds,
    fetchInactiveProjectTrackingSummaries,

    fetchRoles,

    fetchTenants,
    fetchTenantById,
    fetchUsersByTenantId,

    fetchWorkbook,
    fetchReportStaffingIssues,
    fetchDashboardProjectIssues,

    fetchReportWeekOverview,
    fetchReportTeamLead,
    fetchMyProjectStars,
    useCreateProjectStar,
    useDeleteProjectStar,

    setAllocation,
    setProjectPlan,
  };
}

async function fetchWithAuth(apiToken: string, path: string, opts = {}) {
  if (apiToken === "") {
    throw Error("api token missing");
  }

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${apiToken}`,
    },
    ...opts,
  };

  return fetch(baseURL + path, options).then((res) => {
    if (res.status === 204) {
      return;
    }
    if (res.status >= 200 && res.status < 300) {
      return res.json();
    }

    if (res.status === 404) {
      throw new NotFoundError(res.statusText);
    }

    throw Error(res.statusText);
  });
}

async function fetchWithoutAuth(path: string, opts = {}) {
  const options = {
    headers: {
      "Content-Type": "application/json",
    },
    ...opts,
  };

  return fetch(baseURL + path, options).then((res) => {
    if (res.status === 204) {
      return;
    }
    if (res.status >= 200 && res.status < 300) {
      return res.json();
    }

    if (res.status === 404) {
      throw new NotFoundError(res.statusText);
    }

    throw Error(res.statusText);
  });
}

function param2query(params: any) {
  const keys = Object.keys(params);

  if (keys.length === 0) {
    return "";
  }
  return keys.map((key) => key + "=" + params[key]).join("&");
}
