import { Holiday, UserAndProjectUser, UserWorkDaySchedule } from ".";
import { Allocation } from "./allocation";
import { Project } from "./project";
import { Timeline } from "./timeline";

export enum BookingMode {
  View = 1,
  Edit,
}

export interface WeekKinds {
  booked: number;
  sales: number;
  reserved: number;
  absence: number;
  holiday: number;
}

export interface WeekSummary {
  amount: number;
  totalAmount: number;
  maxHours: number;
  date: Date;
  year: number;
  month: number;
  week: number;
  projects: Map<number, number>;
  kinds: WeekKinds;
  bookings: Array<Booking>;
}

export interface WeekMetadata {
  beforeProject: boolean;
  afterProject: boolean;
  projectStart: boolean;
  projectRunning: boolean;
  currentWeek: boolean;
  beforeCurrentWeek: boolean;
}

export interface Booking {
  amount: number;
  date: Date;
  user_id: number;
  project_id: number;
  project_user_id: number;
  kind: BookingKind;
  id: number;
}

export enum BookingKind {
  Booked = "booked",
  Sales = "sales",
  Reserved = "reserved",
}

export interface BookingOptions {
  project?: Project;
  userAndProjectUser?: UserAndProjectUser;
  bookings: Array<Booking>;
  absences?: Array<Absence>;
  holidays?: Array<Holiday>;
  schedules?: Array<UserWorkDaySchedule>;
  countAbsence?: boolean;
  timeline: Timeline;
  mode?: BookingMode;
  showPopover?: boolean;
  kind?: BookingKind;
  summary?: boolean;
  visibleKinds?: BookingKind[];
  maxHours?: number;
  onChange?: (a: Allocation) => Promise<Allocation>;
  showFTE?: boolean;
}

export interface Absence {
  id: number;
  date: string;
  user_id: number;
}
