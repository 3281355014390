import "./instrument";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Toaster } from "react-hot-toast";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Microsoft } from "./components/Auth/Microsoft";
import "./index.scss";
import { AuthProvider } from "./Providers/AuthProvider";
import routes from "./routes/routes";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (cnt: number, err: Error) => {
        // don't retry not found
        if (err instanceof NotFoundError) {
          return false;
        }

        if (cnt >= 2) {
          return false;
        }

        return true;
      },
    },
  },
});

export const baseURL = import.meta.env.VITE_API_URL ?? "http://localhost:3000";
let router = createBrowserRouter(routes);

console.log("app load", window.performance.now());

// @ag-grid-community/core will always be implicitly available
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { SearchProvider } from "./Providers/SearchProvider";
import { NotFoundError } from "./Api";

ModuleRegistry.registerModules([ClientSideRowModelModule]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <StrictMode>
    <HelmetProvider>
      <Microsoft>
        <QueryClientProvider client={queryClient}>
          <SearchProvider>
            <AuthProvider>
              <Toaster
                position="top-center"
                gutter={8}
                toastOptions={{
                  className: "z-10",
                }}
              />
              <RouterProvider router={router} />
              {!import.meta.env.PROD && (
                <ReactQueryDevtools buttonPosition="bottom-right" />
              )}
            </AuthProvider>
          </SearchProvider>
        </QueryClientProvider>
      </Microsoft>
    </HelmetProvider>
  </StrictMode>,
);
