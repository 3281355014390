import {
  Absence,
  Booking,
  BookingKind,
  Holiday,
  Timeline,
  UserWorkDaySchedule,
  WeekKinds,
  WeekMetadata,
  WeekSummary,
} from "../../models";
import {
  date2weekNum,
  sameWeek,
  weekAfter,
  weekBefore,
  WeekInfo,
  weekStart,
  weekWithinSpan,
} from "../../utils";
import { hoursForWeek } from "../../utils/schedule";

export function containsAbsence(ws: WeekSummary) {
  return ws.kinds["absence"] > 0;
}

export function containsHoliday(ws: WeekSummary) {
  return ws.kinds["holiday"] > 0;
}

export function emptyKindSummary(): WeekKinds {
  return {
    booked: 0,
    sales: 0,
    reserved: 0,
    holiday: 0,
    absence: 0,
  };
}

export function emptyWeekSummary(d: Date): WeekSummary {
  let date = weekStart(d);
  let year = date.year();
  let month = date.month();
  let week = date2weekNum(date.toDate());
  const k = `${year}-${week}`;

  return {
    amount: 0,
    key: k,
    date: date.toDate(),
    maxHours: 40,
    totalAmount: 0,
    year: year,
    month: month,
    week: week,
    bookings: [],
    projects: new Map<number, number>(),
    kinds: emptyKindSummary(),
  } as WeekSummary;
}

export function buildWeekSummaries(
  projectId: number | undefined,
  bookings: Array<Booking>,
  countAbsence: boolean,
  holidays: Array<Holiday> | undefined,
  absences: Array<Absence> | undefined,
  maxHours: number,
  visibleKinds: BookingKind[],
  schedules: Array<UserWorkDaySchedule> | undefined,
): Map<string, WeekSummary> {
  if (bookings === undefined) {
    bookings = [];
  }

  let weeks = new Map<string, WeekSummary>();

  bookings = bookings.filter((bk) => {
    if (!visibleKinds.length) return true;
    return visibleKinds.includes(bk.kind);
  });

  bookings.forEach((b) => {
    let date = weekStart(b.date);
    let year = date.year();
    let week = date2weekNum(date.toDate());
    b.date = date.toDate();
    const k = `${year}-${week}`;
    const amount = b.amount;

    let w = weeks.get(k);
    if (w === undefined) {
      w = emptyWeekSummary(b.date);
    }

    let projects = w.projects;

    if (
      b.project_id === projectId ||
      projectId === undefined ||
      projectId === 0
    ) {
      w.amount += amount;
    }

    const kind = b.kind;

    if (kind === BookingKind.Sales) {
      w.kinds["sales"] += amount;
    } else if (kind === BookingKind.Reserved) {
      w.kinds["reserved"] += amount;
    } else {
      w.kinds["booked"] += amount;
    }

    if (projects.get(b.project_id) === undefined) {
      projects.set(b.project_id, 0);
    }

    const ev = projects.get(b.project_id) ?? 0;
    projects.set(b.project_id, ev + amount);

    w.bookings.push(b);
    weeks.set(k, w);
  });

  if (holidays !== undefined) {
    holidays.forEach((h) => {
      let date = weekStart(new Date(h.date));
      let year = date.year();
      let week = date2weekNum(date.toDate());

      const k = `${year}-${week}`;
      const amount = 8; // todo: some days are half days

      let w = weeks.get(k);
      if (w === undefined) {
        w = emptyWeekSummary(date.toDate());
      }

      if (countAbsence) {
        w.amount += amount;
      }
      w.kinds["holiday"] += amount;
      if (w.kinds["holiday"] > 40) {
        w.kinds["holiday"] = 40;
      }

      weeks.set(k, w);
    });
  }

  if (absences !== undefined) {
    absences.forEach((a) => {
      let date = weekStart(new Date(a.date));
      let year = date.year();
      let week = date2weekNum(date.toDate());

      const k = `${year}-${week}`;
      const amount = 8;

      let w = weeks.get(k);
      if (w === undefined) {
        w = emptyWeekSummary(date.toDate());
      }

      if (countAbsence) {
        w.amount += amount;
      }
      w.kinds["absence"] += amount;

      weeks.set(k, w);
    });
  }

  weeks.forEach((v) => {
    const hours = schedules ? hoursForWeek(v.date, schedules) : 0;
    v.totalAmount = sumTotalAmount(v.kinds);
    v.maxHours = hours > 0 ? hours : maxHours;
  });

  return weeks;
}

export function sumTotalAmount(kinds: WeekKinds) {
  return (
    kinds.absence + kinds.booked + kinds.reserved + kinds.sales + kinds.holiday
  );
}

export function weekKey(w: WeekInfo): string {
  return `${w.year}-${w.week}`;
}

export function buildWeekMetadata(
  weeks: Array<WeekInfo>,
  timeline: Timeline,
): Map<string, WeekMetadata> {
  let m = new Map<string, WeekMetadata>();
  weeks.forEach((w) => {
    const k = weekKey(w);
    m.set(k, buildWeekMetadataForWeek(w.date, timeline));
  });

  return m;
}

function buildWeekMetadataForWeek(d: Date, timeline: Timeline): WeekMetadata {
  const beforeProject = weekBefore(d, timeline.project.start);
  const projectStart = sameWeek(d, timeline.project.start);
  const afterProject = weekAfter(d, timeline.project.end);
  const projectRunning = weekWithinSpan(d, timeline.project);
  const currentWeek = sameWeek(d, timeline.today);
  const beforeCurrentWeek = weekBefore(d, timeline.today);

  return {
    beforeProject: beforeProject,
    afterProject: afterProject,
    projectStart: projectStart,
    projectRunning: projectRunning,
    currentWeek: currentWeek,
    beforeCurrentWeek: beforeCurrentWeek,
  };
}

let bookingsByWeek = 0;
export function collectBookingsByWeek(
  weeks: Array<WeekInfo>,
  weekSummaries: Map<string, WeekSummary>,
): Map<string, WeekSummary> {
  if (weeks === null || weeks.length === 0) {
    throw Error("invalid or empty week passed into bookingsByWeek");
  }

  console.log("bookings " + bookingsByWeek++);

  let m = new Map<string, WeekSummary>();

  weeks.forEach((w) => {
    const k = weekKey(w);
    const summary = weekSummaries.get(k);

    if (summary) {
      m.set(k, summary);
    } else {
      m.set(k, emptyWeekSummary(w.date));
    }
  });

  return m;
}
