import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../Providers/AuthProvider";
import { Modal, Select } from "../../components/Antd";
import type {
  Allocation,
  Booking,
  BookingOptions,
  Project,
  ProjectUser,
  StaffingSuggestion,
  Timeline,
  User,
  UserAndProjectUser,
} from "../../models";
import { BookingKind, BookingMode } from "../../models";
import { dateSpanToQueryParam, formatDate, parseDate } from "../../utils";
import { Bookings } from "../Bookings/Bookings";
import {
  BookingLine,
  BookingsHeader,
  BookingTable,
} from "../Bookings/Structure";
import toast from "react-hot-toast";

interface SuggestStaffingModalProps {
  project: Project;
  timeline: Timeline;
  projectUsersWithUsers: Array<UserAndProjectUser>;
  isOpen: boolean;
  setOpen: (b: boolean) => void;
}

interface SuggestStaffingProps {
  project: Project;
  timeline: Timeline;
  user: User;
  projectUser: ProjectUser;
  updateSuggestions: (k: string, v: Allocation) => void;
  kind: BookingKind;
  bookings: Array<Booking>;
}

function initialBookings(bookings: Booking[]): Map<string, Allocation> {
  const m = new Map<string, Allocation>();

  for (const b of bookings) {
    const dateString = formatDate(b.date);

    const allocation = {
      project_user_id: Number(b.project_user_id),
      projectId: b.project_id.toString(),
      userId: b.user_id,
      date: dateString,
      amount: b.amount,
      kind: b.kind,
    } as Allocation;

    m.set(dateString, allocation);
  }

  return m;
}

export function SuggestStaffingModal(props: SuggestStaffingModalProps) {
  const { api, user } = useAuth();

  const [kind, setKind] = useState<BookingKind>(BookingKind.Booked);

  const params = dateSpanToQueryParam(props.timeline.view);
  const fetchBookings = api.fetchProjectBookingsByUserId(
    user.id.toString(),
    params,
  );
  const createStaffingSuggestionMutation = api.createStaffingSuggestion(() => {
    setTimeout(() => {
      console.log("callback here to update displayed staffing");
    }, 1000);
  });

  const createStaffingSuggestion = useCallback(
    (projectUser: ProjectUser, suggestions: Map<string, Allocation>) => {
      console.log("creating staffing suggestion", projectUser.id, suggestions);

      const suggestionArr = Array.from(suggestions.entries()).map(([k, v]) => ({
        date: k,
        amount: v.amount,
        kind: v.kind,
      }));

      if (!suggestionArr.length) {
        props.setOpen(false);
        return;
      }

      createStaffingSuggestionMutation.mutate({
        project_user_id: projectUser.id,
        suggestions: suggestionArr,
      } as StaffingSuggestion);

      props.setOpen(false);
      toast.success("Staffing Suggestion Created. PMs have been informed.", {
        duration: 5000,
      });
    },

    [props.setOpen],
  );

  useEffect(() => {
    if (!fetchBookings.isSuccess) {
      return;
    }

    const allBookings = fetchBookings.data;
    const projectBookings = allBookings.filter(
      (b) => b.project_id === props.project.id,
    );
    const m = initialBookings(projectBookings);
    setSuggestions(m);
  }, [fetchBookings.isSuccess, fetchBookings.data]);

  const [suggestions, setSuggestions] = useState<Map<string, Allocation>>(
    initialBookings([]),
  );

  if (fetchBookings.isPending) {
    return <div>loading</div>;
  }

  if (fetchBookings.isError) {
    return <div>error</div>;
  }

  const updateSuggestions = (k: string, v: Allocation) => {
    const newSuggestions = new Map<string, Allocation>(suggestions);
    const dateKey = formatDate(parseDate(k));
    newSuggestions.set(dateKey, v);
    setSuggestions(newSuggestions);
  };

  const currentProjectUser = props.projectUsersWithUsers.find(
    (pu) => pu.user.id === user.id,
  )?.project_user;

  if (currentProjectUser === undefined) {
    return;
  }

  return (
    <>
      <Modal
        title="Suggest Staffing"
        centered
        open={props.isOpen}
        okButtonProps={{ disabled: suggestions.size === 0 }}
        onOk={() => createStaffingSuggestion(currentProjectUser, suggestions)}
        onCancel={() => props.setOpen(false)}
        destroyOnClose={true}
        width={1000}
      >
        <Select
          style={{ width: "100%" }}
          value={kind}
          onChange={(e) => setKind(e)}
          placeholder="Please select"
          options={kindOptions}
        />
        <SuggestStaffingForProject
          {...props}
          bookings={fetchBookings.data}
          user={user}
          kind={kind}
          projectUser={currentProjectUser}
          updateSuggestions={updateSuggestions}
        />
      </Modal>
    </>
  );
}
const kindOptions = [
  { label: "Booked", value: BookingKind.Booked },
  { label: "Sales", value: BookingKind.Sales },
  { label: "Reserved", value: BookingKind.Reserved },
];

export function SuggestStaffingForProject(props: SuggestStaffingProps) {
  const { user } = useAuth();

  if (props.projectUser === undefined) {
    return <div>you are not on this project</div>;
  }

  const addSuggestion = useCallback(
    async (a: Allocation): Promise<Allocation> => {
      return new Promise((resolve) => {
        props.updateSuggestions(a.date, a);
        setTimeout(() => {
          resolve(a);
        }, 150);
      });
    },
    [props.updateSuggestions],
  );

  const options = {
    project: props.project,
    timeline: props.timeline,
    user: user,
    mode: BookingMode.Edit,
    bookings: props.bookings,
    userAndProjectUser: {
      user: user,
      project_user: props.projectUser,
    },
    kind: props.kind,
    onChange: addSuggestion,
  } as BookingOptions;

  return (
    <div>
      <BookingTable width="0px">
        <BookingLine>
          <BookingsHeader timeline={props.timeline} />
        </BookingLine>
        <BookingLine>
          <Bookings options={options} />
        </BookingLine>
      </BookingTable>
    </div>
  );
}
