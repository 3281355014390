import { Helmet } from "react-helmet-async";

interface TitleProps {
  text?: string;
  title?: string;
  extra?: React.ReactNode;
  children?: React.ReactNode;
  style?: any;
}

export function MainTitle(props: TitleProps) {
  const c: any = props.children !== undefined ? props.children : props.text;
  const title = props.title ?? props.text ?? "";
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="flex flex-row justify-between">
        <div className="-mt-1 mb-2 font-sans text-2xl font-bold">
          {c}
        </div>
        {props.extra ? props.extra : null}
      </div>
    </>
  );
}

export function SubTitle({ text, children }: TitleProps) {
  const c: any = children !== undefined ? children : text;

  return <div className="text-xl">{c}</div>;
}
