import { NamedRole, Project, ProjectUser, User } from "../models";

export const isAdmin = (roles: Array<NamedRole>) =>
  roles?.includes(NamedRole.Admin);

export const isStaffingManager = (roles: Array<NamedRole>) =>
  roles.includes(NamedRole.StaffingManager);

export function canViewAllSuggestions(roles: Array<NamedRole>): boolean {
  return isAdmin(roles) || isLocationViewer(roles);
}

export function canViewLocations(roles: Array<NamedRole>): boolean {
  return isAdmin(roles) || isLocationViewer(roles);
}

export function canViewTenants(roles: Array<NamedRole>): boolean {
  return isAdmin(roles) || isTenantViewer(roles);
}

export function isLocationViewer(roles: Array<NamedRole>): boolean {
  return roles.includes(NamedRole.LocationViewer);
}

export function isTenantViewer(roles: Array<NamedRole>): boolean {
  return roles.includes(NamedRole.TenantViewer);
}

export function canEditUser(roles: Array<NamedRole>): boolean {
  return isAdmin(roles);
}
export function canAddSkills(roles: Array<NamedRole>): boolean {
  return isAdmin(roles);
}
export function canUpdateSkills(roles: Array<NamedRole>): boolean {
  return isAdmin(roles);
}

export function canViewWorkbook(roles: Array<NamedRole>): boolean {
  return isAdmin(roles) || isStaffingManager(roles);
}

export function canViewTimeEntries(
  _userId: string,
  _currentUser: User,
  roles: Array<NamedRole>,
): boolean {
  // todo: check for access for teamlead
  return isAdmin(roles) || isStaffingManager(roles);
}

export function canManageProjectUserRequests(roles: Array<NamedRole>) {
  return isAdmin(roles) || isStaffingManager(roles);
}

export function isProjectManager(
  roles: Array<NamedRole>,
  project: Project,
  projectUser: ProjectUser | undefined,
) {
  if (isAdmin(roles) || isStaffingManager(roles)) {
    return true;
  }

  if (projectUser === undefined) {
    return false;
  }

  if (
    projectUser.role === "Project Manager" &&
    projectUser.project_id === project.id
  ) {
    return true;
  }

  return false;
}

export function canEditProjectStaffing(
  roles: Array<NamedRole>,
  project: Project,
  projectUser: ProjectUser | undefined,
) {
  return isProjectManager(roles, project, projectUser);
}

export function canSuggestStaffing(
  currentUser: User,
  project: Project,
  projectUser: ProjectUser | undefined,
): boolean {
  if (projectUser === undefined) {
    return false;
  }
  if (
    projectUser.project_id === project.id &&
    projectUser.user_id === currentUser.id
  ) {
    return true;
  }

  return false;
}
