import { Card } from "@/components/Card";
import { ProjectHeader } from "@/components/Projects/Header";
import { ProjectStaffing } from "@/components/Projects/Staffing";
import { LinkToSalesforceProject } from "@/utils/project";
import { Avatar, Button, Popover, Tooltip } from "antd";
import {
  BookOpenCheck,
  ChartArea,
  CircleArrowDown,
  CircleArrowUp,
  CircleMinus,
  Hourglass,
  LucideProps,
  Users,
} from "lucide-react";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../../Providers/AuthProvider";
import { isProjectManager } from "../../Providers/permissions";
import { Loading } from "../../components/Status/Loading";
import {
  ProjectRole,
  ProjectStats,
  ProjectTask,
  TimeEntry,
  Timeline,
  User,
  UserAndProjectUser,
} from "../../models";
import { Project } from "../../models/project";
import {
  sortProjectRolesByName,
  sortProjectTasksByName,
  userImageUrl,
  userName,
} from "../../utils";
import {
  buildTimelineForProject,
  weekBefore,
  weekStart,
} from "../../utils/date";
import Bars from "./Bars";
import {
  calcEstimatedHours,
  calcStats,
  ProjectHourSummary,
  StatSummary,
  sumTotalHours,
} from "./stats";
import { ProjectStaffingProvider } from "@/Providers/ProjectStaffingProvider";

interface ProjectControllingProps {
  projectId: string;
}

function ProjectControlling(props: ProjectControllingProps) {
  const projectId = props.projectId;

  const { api } = useAuth();

  const fetchProjectTasks = api.fetchProjectTasksByProjectId(projectId);
  const fetchProjectRoles = api.fetchProjectRolesByProjectId(projectId);
  const fetchTimeEntries = api.fetchTimeEntriesByProjectId(projectId);

  if (
    fetchProjectTasks.isPending ||
    fetchTimeEntries.isPending ||
    fetchProjectRoles.isPending
  ) {
    return <Loading />;
  }

  if (
    fetchProjectTasks.isError ||
    fetchTimeEntries.isError ||
    fetchProjectRoles.isError
  ) {
    return <div>error</div>;
  }
  const tasks = fetchProjectTasks.data;
  const timeEntries = fetchTimeEntries.data;
  const projectRoles = fetchProjectRoles.data;

  return (
    <div className="flex flex-col gap-4">
      <div>
        <b>Roles</b>
        <ProjectRoles project_roles={projectRoles} time_entries={timeEntries} />
      </div>
      <div>
        <b>Tasks</b>
        <ProjectTasks project_tasks={tasks} time_entries={timeEntries} />
      </div>
    </div>
  );
}

function ProjectCard(props: {
  icon: React.ComponentType;
  children: React.ReactNode;
}) {
  const Icon = props.icon as React.ComponentType<LucideProps>;

  return (
    <Card>
      <div className="flex gap-4">
        <div className="flex items-center">
          <Icon size={48} strokeWidth={1.5} />
        </div>
        <div className="@container w-full">{props.children}</div>
      </div>
    </Card>
  );
}

function ProjectBudgetCard(props: { stats: ProjectStats }) {
  const s = props.stats;
  const totalBudget = Number(s.total_role_budget ?? 0);
  const totalUsed = Number(s.total_hours);
  const remaining = totalBudget - totalUsed;
  const percentRemaining =
    totalBudget > 0 ? (remaining / totalBudget) * 100 : 0;
  const showOverbudget = percentRemaining < 0;
  const overBudget = totalUsed - totalBudget;
  const burnRate = s.burn_rate_percentage ?? 0;

  return (
    <ProjectCard icon={Hourglass}>
      <div className="">
        <div className="text-2xl font-semibold">
          {percentRemaining.toFixed(2)}%
          {showOverbudget && (
            <span className="pl-1 text-sm font-bold text-red-600">
              Overbudget!
            </span>
          )}
        </div>
        {showOverbudget ? (
          <div className="font-normal text-gray-700">
            <b>{overBudget}</b> hours over budget ({totalUsed} of {totalBudget})
          </div>
        ) : (
          <div className="font-normal text-gray-700">
            {remaining} hours remaining from {totalBudget}
          </div>
        )}
        <div className="text-2xl font-semibold">{burnRate} hrs/week</div>
        <div className="font-normal text-gray-700">Run Rate last months</div>
      </div>
    </ProjectCard>
  );
}

function DiffArrow(props: { old: number; new: number }) {
  const size = 16;

  const oldV = Number(props.old ?? 0);
  const newV = Number(props.new ?? 0);

  if (oldV > newV) {
    return <CircleArrowDown size={size} color="red" />;
  }
  if (newV > oldV) {
    return <CircleArrowUp size={size} color="green" />;
  }

  return (
    <span>
      <CircleMinus size={size} />
    </span>
  );
}

function ProjectTrackingCard(props: { stats: ProjectStats }) {
  const s = props.stats;

  const hoursThisWeek = Number(s.hours_this_week ?? 0);
  const hoursLastWeek = Number(s.hours_last_week ?? 0);
  const hoursPrevWeek = Number(s.hours_prev_week ?? 0);

  return (
    <ProjectCard icon={BookOpenCheck}>
      <div className="">
        <Popover
          content={<div>Tracked {hoursThisWeek} hours this week</div>}
          placement="left"
        >
          <div className="flex flex-row items-center gap-1 text-2xl font-semibold">
            {s.hours_this_week}
            <DiffArrow old={hoursLastWeek} new={hoursThisWeek} />
          </div>
          <div className="font-normal text-gray-700">
            <div>Tracked This Week</div>
          </div>
        </Popover>
        <Popover
          content={
            <div>
              Tracked {hoursLastWeek} hours last week and tracked{" "}
              {hoursPrevWeek} hours the week prior.
            </div>
          }
          placement="left"
        >
          <div className="flex items-center gap-1 text-2xl font-semibold">
            {s.hours_last_week}
            <DiffArrow old={hoursPrevWeek} new={hoursLastWeek} />
          </div>
          <div className="font-normal text-gray-700">
            <div>Tracked Last Week</div>
          </div>
        </Popover>
      </div>
    </ProjectCard>
  );
}

function BenchedTeamInfo(props: { users: User[] }) {
  return (
    <div>
      <p className="pb-2">
        People who have not tracked in over a month and aren't staffed in the
        future.
      </p>
      <ul>
        {props.users.map((u) => (
          <li key={u.id}>
            {u.given_name} {u.family_name}
          </li>
        ))}
      </ul>
    </div>
  );
}

function ProjectTeamCard(props: { stats: ProjectStats }) {
  const s = props.stats;
  return (
    <ProjectCard icon={Users}>
      <div className="">
        <div className="text-2xl font-semibold">
          {s.active_trackers_last_month}
        </div>
        <div className="flex flex-row items-center gap-2 font-normal text-gray-700">
          Active Team Members
        </div>
        <Popover
          title="Benched People"
          content={<BenchedTeamInfo users={s.inactive_members_list} />}
          placement={"bottom"}
        >
          <div className="text-2xl font-semibold">
            {s.inactive_unbooked_members}
          </div>
          <div className="font-normal text-gray-700">Benched Members</div>
        </Popover>
      </div>
    </ProjectCard>
  );
}

function ProjectCards(props: { stats: ProjectStats }) {
  const s = props.stats;

  return (
    <div className="grid grid-cols-2 gap-4 lg:grid-cols-4">
      <ProjectBudgetCard stats={props.stats} />
      <ProjectTrackingCard stats={props.stats} />
      <ProjectTeamCard stats={props.stats} />
      <ProjectCard icon={ChartArea}>
        <div className="">
          <div className="text-3xl font-semibold">{s.future_booked_hours}</div>
          <div className="flex flex-row items-center gap-2 font-normal text-gray-700">
            Hours Staffed
          </div>
        </div>
      </ProjectCard>
    </div>
  );
}

export function ProjectShow() {
  const { projectId } = useParams();
  const { api, user, roles } = useAuth();

  if (projectId === undefined) {
    return <div>error</div>;
  }

  const fetchProjects = api.fetchProjectById(projectId);
  const fetchProjectTasks = api.fetchProjectTasksByProjectId(projectId);
  const fetchProjectRoles = api.fetchProjectRolesByProjectId(projectId);
  const fetchProjectStats = api.fetchProjectStatsByProjectId(projectId);
  const fetchTimeEntries = api.fetchTimeEntriesByProjectId(projectId);

  if (
    fetchProjectStats.isPending ||
    fetchProjects.isPending ||
    fetchProjectTasks.isPending ||
    fetchTimeEntries.isPending ||
    fetchProjectRoles.isPending
  ) {
    return <Loading />;
  }

  if (
    fetchProjectStats.isError ||
    fetchProjects.isError ||
    fetchProjectTasks.isError ||
    fetchTimeEntries.isError ||
    fetchProjectRoles.isError
  ) {
    return <div>error</div>;
  }

  const project = fetchProjects.data.project;
  const projectUsersWithUsers = fetchProjects.data.project_users_with_users;
  const timeEntries = fetchTimeEntries.data;
  const projectRoles = fetchProjectRoles.data;
  const projectStats = fetchProjectStats.data;

  const estimatedHours = calcEstimatedHours(projectRoles);
  const hourStats = sumTotalHours(timeEntries);

  const timeline = buildTimelineForProject(project);
  const today = new Date();

  const myProjectUser = projectUsersWithUsers.find(
    (pu) => pu.user.id === user.id,
  );
  // const canSeeProjectControlling = canEditProjectStaffing(
  //   roles,
  //   project,
  //   myProjectUser?.project_user,
  // );
  const isPM = isProjectManager(roles, project, myProjectUser?.project_user);

  if (weekBefore(today, timeline.view.start)) {
    timeline.view.start = weekStart(new Date()).toDate();
  }

  return (
    <div className="flex flex-col gap-4">
      <ProjectHeader projectId={projectId}>
        {isPM && (
          <>
            <Link to={`/projects/${project.id}/staffing_suggestions`}>
              <Button type="primary">Suggestions</Button>
            </Link>
            <Link to={`/projects/${project.id}/planner`}>
              <Button type="primary">Planner</Button>
            </Link>
            <Link to={`/reports/projects/${project.id}`}>
              <Button type="primary">Report</Button>
            </Link>
            <LinkToSalesforceProject project={project}>
              <Button type="primary">Salesforce</Button>
            </LinkToSalesforceProject>
          </>
        )}
      </ProjectHeader>
      <ProjectCards stats={projectStats} />
      <ProjectStaffingProvider>
        <div className="flex flex-row gap-4">
          <div className="min-w-80 max-w-80 flex flex-col gap-4">
            <ProjectInfo
              timeline={timeline}
              project={project}
              projectStats={projectStats}
              projectUsersWithUsers={projectUsersWithUsers}
              estimatedHours={estimatedHours}
              hourStats={hourStats}
            />
            <ProjectControlling projectId={projectId} />
          </div>
          <div className="flex-grow overflow-hidden">
            <ProjectStaffing
              timeline={timeline}
              project={project}
              projectUsersWithUsers={projectUsersWithUsers}
            />
          </div>
        </div>
      </ProjectStaffingProvider>
    </div>
  );
}

interface ProjectRoleProps {
  project_roles: Array<ProjectRole>;
  time_entries: Array<TimeEntry>;
}
interface ProjectRoleWithTimeEntries {
  role: ProjectRole;
  time_entries: Array<TimeEntry>;
  stats?: StatSummary;
}

export function ProjectRoles(props: ProjectRoleProps) {
  if (props.project_roles.length === 0) {
    return <>No roles defined</>;
  }

  let roles = new Map<number, ProjectRoleWithTimeEntries>();
  sortProjectRolesByName(props.project_roles).forEach((t) => {
    roles.set(t.id, {
      role: t,
      time_entries: [],
    });
  });

  props.time_entries.forEach((te) => {
    const project_role_id = te.project_role_id;
    if (project_role_id === undefined) {
      return;
    }

    const role = roles.get(project_role_id);
    if (role === undefined) {
      return;
    }

    role.time_entries.push(te);
  });

  for (let roleId of roles.keys()) {
    const t = roles.get(roleId);
    if (t === undefined) {
      continue;
    }

    t.stats = calcStats(t.role.budget_hours, t.time_entries);
  }

  const roleBars = [...roles.values()].map((t) => {
    return {
      id: Number(t.role.id),
      name: t.role.name,
      budgetHours: t.stats?.estimate ?? 0,
      usedHours: t.stats?.used ?? 0,
    };
  });

  return <Bars data={roleBars} options={false} />;
}

export function ProjectInfo(props: {
  project: Project;
  projectStats: ProjectStats;
  projectUsersWithUsers: Array<UserAndProjectUser>;
  timeline: Timeline;
  hourStats: ProjectHourSummary;
  estimatedHours: number;
}) {
  const projectUsersWithUsers = props.projectUsersWithUsers;

  const projectManagers = projectUsersWithUsers
    .filter((pu) => pu.project_user.role === "Project Manager")
    .map((pu) => pu.user);

  return (
    <div className="flex flex-col">
      <div>
        <b>Hours</b>: {props.hourStats.totalHours} / {props.estimatedHours}
      </div>

      <div>
        <b>Project Managers:</b>
        <ProjectManagers projectManagers={projectManagers} />
      </div>
    </div>
  );
}

function ProjectManagers(props: { projectManagers: User[] }) {
  return (
    <div>
      <Avatar.Group>
        {props.projectManagers.map((user) => (
          <Tooltip key={user.id} title={userName(user)} placement="top">
            <Avatar size="large" src={userImageUrl(user.id.toString())} />
          </Tooltip>
        ))}
      </Avatar.Group>
    </div>
  );
}

interface ProjectTaskProps {
  project_tasks: Array<ProjectTask>;
  time_entries: Array<TimeEntry>;
}

interface ProjectTaskWithTimeEntries {
  task: ProjectTask;
  time_entries: Array<TimeEntry>;
  stats?: StatSummary;
}

export function ProjectTasks(props: ProjectTaskProps) {
  if (props.project_tasks.length === 0) {
    return <div>No tasks</div>;
  }

  const tasks = new Map<number, ProjectTaskWithTimeEntries>();
  const sortedTasks = sortProjectTasksByName(props.project_tasks);
  for (const t of sortedTasks) {
    tasks.set(t.id, {
      task: t,
      time_entries: [],
    });
  }

  for (const te of props.time_entries) {
    const project_task_id = te.project_task_id;
    if (project_task_id === undefined) {
      continue;
    }

    const task = tasks.get(project_task_id);
    if (task === undefined) {
      continue;
    }

    task.time_entries.push(te);
  }

  for (const taskId of tasks.keys()) {
    const t = tasks.get(taskId);
    if (t === undefined) {
      continue;
    }

    t.stats = calcStats(t.task.budget_hours, t.time_entries);
  }

  const roleBars = [...tasks.values()].map((t) => {
    return {
      id: Number(t.task.id),
      name: t.task.name,
      budgetHours: t.stats?.estimate ?? 0,
      usedHours: t.stats?.used ?? 0,
    };
  });

  return <Bars data={roleBars} options={false} />;
}
