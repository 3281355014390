import { useMutation } from "@tanstack/react-query";
import { debounce } from "lodash-es";
import { useState } from "react";
import type { WeekKinds } from "../../models";
import { BookingKind, BookingMode } from "../../models";
import type { Allocation } from "../../models/allocation";
import { addHours, formatDate } from "../../utils";
import { MutateStatus } from "../Generic/MutateStatus";
import { BookingPopover } from "./BookingPopover";
import type { BookingCellProps } from "./Bookings";
import {
  containsAbsence,
  containsHoliday,
  emptyKindSummary,
  sumTotalAmount,
} from "./logic";
import { bookingStyle, calcExtraStyle, classesForBooking } from "./Styling";
import { BookingCellBadge } from "./ViewCell";

export function BookingCellEdit(props: BookingCellProps) {
  const weekMetadata = props.weekMetadata;
  const classNames = [
    "allocation",
    "edit-mode",
    ...classesForBooking(weekMetadata),
  ];

  const className = classNames.join(" ");
  const mut = useMutation({
    mutationKey: [],
    mutationFn: props.options.onChange,
  });

  return (
    <td className={className}>
      <Cell {...props} mutation={mut} />
    </td>
  );
}

function Cell(props: BookingCellProps) {
  const weekSummary = props.weekSummary;
  const booking = weekSummary;

  const hasVacation = containsAbsence(booking);
  const hasHoliday = containsHoliday(booking);

  if (hasVacation || hasHoliday) {
    return (
      <BookingCellBadge holiday={hasHoliday} vacation={hasVacation}>
        <InnerCell {...props} />
      </BookingCellBadge>
    );
  }

  return <InnerCell {...props} />;
}

function parseInputValue(v: string): number {
  let iv = Number.parseInt(v, 10);
  if (Number.isNaN(iv)) {
    iv = 0;
  }

  return iv;
}

function InnerCell(props: BookingCellProps) {
  const mut = props.mutation;
  if (mut === undefined) {
    throw "missing mutation";
  }

  const weekSummary = props.weekSummary;
  const weekMetadata = props.weekMetadata;
  const booking = weekSummary;

  const [amount, setAmount] = useState(weekSummary.amount);
  const [style, setStyle] = useState<{}>(
    bookingStyle(booking, BookingMode.Edit),
  );
  const [extraStyle, setExtraStyle] = useState<{}>(calcExtraStyle(booking));

  const options = props.options;
  const userAndProjectUser = options.userAndProjectUser;
  if (userAndProjectUser === undefined) {
    throw Error("can not edit a booking without a project user");
  }

  const projectUserId = userAndProjectUser.project_user.id;
  const project = options.project;
  if (project === null || project === undefined) {
    throw Error("can not edit booking without projectid");
  }

  const visibleAmount = weekMetadata.afterProject ? 0 : amount;
  const amountToShow = 40 - (booking.totalAmount - booking.amount + amount);

  const debouncedRequest = debounce((allocation: Allocation) => {
    mut.reset();
    mut.mutate(allocation);
  }, 500);

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const v = parseInputValue(e.target.value);
    console.log("set amount", v);
    setAmount(v);

    // if before project
    //  allow reserved or presales
    // dont allow booked
    //
    const allocationKind =
      weekMetadata.beforeProject && options.kind == BookingKind.Booked
        ? BookingKind.Sales
        : options.kind;

    // hack, dates have timezones - oh no.
    // todo: dylan
    const date = addHours(booking.date, 5).toDate();
    const allocation = {
      project_user_id: projectUserId,
      date: formatDate(date),
      amount: v,
      kind: allocationKind,
    } as Allocation;

    const wsd = { ...booking };
    const absenceAmount = wsd.kinds.absence;

    wsd.kinds = emptyKindSummary();
    wsd.kinds[allocationKind as keyof WeekKinds] = v;
    wsd.kinds.absence = absenceAmount;
    wsd.amount = v;
    wsd.totalAmount = sumTotalAmount(wsd.kinds);

    setStyle(bookingStyle(wsd, options.mode));
    setExtraStyle(calcExtraStyle(wsd));

    debouncedRequest(allocation);
  }

  return (
    <div className="entry edit relative" style={style}>
      <MutateStatus mutation={mut} />
      <div className="value">
        <input
          type="text"
          value={visibleAmount}
          onFocus={(e) => e.target.select()}
          onMouseUp={(e) => e.preventDefault()}
          onChange={onInputChange}
        />
      </div>
      <div className="extra" style={extraStyle}>
        <BookingPopover ws={props.weekSummary}>{amountToShow}</BookingPopover>
      </div>
    </div>
  );
}
