import { Link } from "react-router-dom";
import { Button } from "../../components/Antd";
import type { BaseUser, Project, User } from "../../models";
import { userImageUrl } from "../../utils";
import { useAuth } from "@/Providers/AuthProvider";

interface UserLinkProps {
  user?: BaseUser;
  image?: "small" | "large" | "medium";
  name?: string;
  id?: string;
}

function nameForUser(user: BaseUser) {
  return `${user.given_name} ${user.family_name}`;
}

export function LinkToUserById(props: { id: number }) {
  if (props.id === null) {
    return 'undefined';
  }

  const { api } = useAuth();
  
  const fetchUser = api.fetchUserById(props.id.toString());

  if (fetchUser.isPending) {
    return <>loading</>
  }

  if (fetchUser.isError) {
    return <>error</>
  }

  return <LinkToUser user={fetchUser.data}/>
}

export function LinkToUser(props: UserLinkProps) {
  const u = props.user;
  const name = props.name ?? (u ? nameForUser(u) : "");
  const id = props.id ?? (u ? u.id.toString() : "");
  const path = `/users/${id}`;

  if (props.image === undefined) {
    return <Link to={path}>{name}</Link>;
  }

  return (
    <span className="flex">
      <span className="pr-2">
        <UserImage size={props.image} id={id} />
      </span>
      <Link to={path}>{name}</Link>
    </span>
  );
}

const imageSize = {
  large: 64,
  medium: 32,
  small: 22,
};

export function UserImage(props: {
  id: string;
  size: "large" | "medium" | "small";
}) {
  const size = imageSize[props.size];
  const url = userImageUrl(props.id);
  return (
    <div
      className="rounded-full bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url("${url}")`,
        width: size,
        minWidth: size,
        height: size,
      }}
    />
  );
}

interface ManagerLinkProps {
  user: User | null;
}
export function LinkToManager(props: ManagerLinkProps) {
  if (props.user !== null) {
    return <LinkToUser user={props.user} />;
  }

  return <span>Nobody</span>;
}

export function LinkToEditUser(props: { id: string }) {
  const path = `/users/${props.id}/edit`;

  return (
    <Link to={path}>
      <Button type="primary" size="small">
        Edit
      </Button>
    </Link>
  );
}

export function LinkToProjectReport(props: { project: Project }) {
  const path = `/reports/projects/${props.project.id}`;

  return <Link to={path}>{props.project.name}</Link>;
}
