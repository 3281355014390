import { CheckOutlined, StopOutlined } from "@ant-design/icons";
import { useState } from "react";
import { Card, Space } from "../../components/Antd";
import {
  BookingOptions,
  Project,
  StaffingSuggestion,
  Timeline,
  User,
  UserAndProjectUser,
} from "../../models";
import { useAuth } from "../../Providers/AuthProvider";
import { bookingsFromSuggestions, userName } from "../../utils";
import { Bookings } from "../Bookings/Bookings";
import {
  BookingLine,
  BookingsHeader,
  BookingTable,
} from "../Bookings/Structure";
import { PopoverButtonConfirm } from "../Generic/PopoverButtonConfirm";
import { Loading } from "../Status/Loading";

interface LineInfoProps {
  user: User;
  onAccept: () => void;
  onReject: () => void;
}
function LineInfo(props: LineInfoProps) {
  const user = props.user;

  return (
    <Space direction="horizontal">
      <span>{userName(user)}</span>
      <PopoverButtonConfirm
        icon={<CheckOutlined />}
        title="Confirm Suggestion"
        okText="Confirm"
        content="Are you sure you want to accept this staffing suggestion?"
        onConfirm={props.onAccept}
      />
      <PopoverButtonConfirm
        icon={<StopOutlined />}
        danger
        title="Reject Suggestion"
        okText="Reject"
        content="Are you sure you want to reject this staffing suggestion?"
        onConfirm={props.onReject}
      />
    </Space>
  );
}

interface StaffingSuggestionsProps {
  project: Project;
  timeline: Timeline;
  projectUsersWithUsers: Array<UserAndProjectUser>;
}

export function StaffingSuggestions(props: StaffingSuggestionsProps) {
  const { api } = useAuth();
  const project = props.project;
  const projectUsersWithUsers = props.projectUsersWithUsers;
  const timeline = props.timeline;

  const { isPending, isError, data } = api.fetchStaffingSuggestionByProjectId(
    project.id.toString(),
  );

  if (isPending) {
    return <Loading />;
  }

  if (isError) {
    return <div>error</div>;
  }

  if (data.length === 0) {
    return null;
  }

  return (
    <Card size="small" title="Staffing Requests">
      <StaffingSuggestionList
        project={project}
        timeline={timeline}
        staffingSuggestions={data}
        projectUsersWithUsers={projectUsersWithUsers}
      />
    </Card>
  );
}

export function StaffingSuggestionList(props: {
  staffingSuggestions: Array<StaffingSuggestion>;
  project: Project;
  timeline: Timeline;
  projectUsersWithUsers: Array<UserAndProjectUser>;
}) {
  const { api } = useAuth();
  const [staffingSuggestions, setStaffingSuggestions] = useState<
    Array<StaffingSuggestion>
  >(props.staffingSuggestions);

  const projectUsersWithUsers = props.projectUsersWithUsers;

  function removeStaffingSuggestion(id: number) {
    setStaffingSuggestions(staffingSuggestions.filter((sr) => sr.id !== id));
  }

  function onAccept(id: number) {
    api
      .acceptStaffingSuggestion(id.toString())
      .then(() => removeStaffingSuggestion(id));
  }

  function onReject(id: number) {
    api
      .rejectStaffingSuggestion(id.toString())
      .then(() => removeStaffingSuggestion(id));
  }

  if (staffingSuggestions.length === 0) {
    return <></>;
  }

  return (
    <BookingTable width="300px">
      <BookingLine>
        <BookingsHeader timeline={props.timeline} />
      </BookingLine>
      {staffingSuggestions.map((sr) => {
        const bookings = bookingsFromSuggestions(
          sr.project_user_id,
          props.project.id,
          sr.suggestions,
        );
        const project_user_and_user = projectUsersWithUsers.find(
          (pu) => pu.project_user.id === sr.project_user_id,
        );

        if (project_user_and_user === undefined) {
          return null;
        }

        const user = project_user_and_user.user;

        const options = {
          timeline: props.timeline,
          bookings: bookings,
          project: props.project,
          userAndProjectUser: project_user_and_user,
        } as BookingOptions;

        return (
          <BookingLine
            key={sr.id}
            lineContent={
              <LineInfo
                user={user}
                onAccept={() => onAccept(sr.id)}
                onReject={() => onReject(sr.id)}
              />
            }
          >
            <Bookings options={options} />
          </BookingLine>
        );
      })}
    </BookingTable>
  );
}
