import { Bookings } from "@/components/Bookings/Bookings";
import { BookingLine, BookingsHeader, BookingTable } from "@/components/Bookings/Structure";
import { Forbidden } from "@/components/Status/Forbidden";
import { InternalError } from "@/components/Status/InternalError";
import { Loading } from "@/components/Status/Loading";
import { MainTitle } from "@/components/Typography";
import { LinkToUser, LinkToUserById } from "@/components/Users/LinkToUser";
import {
  BookingOptions,
  Project,
  StaffingSuggestion,
  StaffingSuggestionAndUserAndProject,
  StaffingSuggestionEntry,
  Timeline,
  User,
} from "@/models";
import { useAuth } from "@/Providers/AuthProvider";
import { canViewAllSuggestions } from "@/Providers/permissions";
import { bookingsFromSuggestions, formatFriendlyDate, formatFriendlyDateWithoutYear, parseDate } from "@/utils";
import { LinkToProject } from "@/utils/project";
import { Card, Select } from "antd";
import { useState } from "react";

function SingleStaffingSuggestion(props: {
  data: StaffingSuggestionAndUserAndProject;
}) {
  const d = props.data;
  const suggestion = d.staffing_suggestion;
  const user = d.user;
  const project = d.project;
  const timeline = buildTimelineFromSuggestions(suggestion.suggestions);

  const bookings = bookingsFromSuggestions(
    suggestion.project_user_id,
    project.id,
    suggestion.suggestions,
  );

  const options = {
    timeline: timeline,
    bookings: bookings,
    project: project,
    maxHours: 40,
  } as BookingOptions;



  return (
    <Card
      size="small"
      extra={<StaffingSuggestionCardExtra project={project} suggestion={suggestion}/>}
      title={<StaffingSuggestionCardTitle project={project}/>}
    >
      <BookingTable width="300px">
        <BookingLine>
          <BookingsHeader timeline={timeline} />
        </BookingLine>
        <BookingLine
          key={suggestion.id}
          lineContent={<StaffingSuggestionLineInfo user={user} suggestion={suggestion}/>}
        >
          <Bookings options={options} />
        </BookingLine>
      </BookingTable>
    </Card>
  )
}
function StaffingSuggestionCardExtra(props: { project: Project, suggestion: StaffingSuggestion }) {
  const { api } = useAuth();
  
  const dateStr = formatFriendlyDate(props.suggestion.created_at);
  const fetchProject = api.fetchProjectById(props.project.id.toString());
  if (fetchProject.isLoading) {
    return null;
  }
  if(fetchProject.isError) {
    return null;
  }

  const projectUsers = fetchProject.data?.project_users_with_users ?? [];
  const projectManagers = projectUsers.filter(u => u.project_user.role === 'Project Manager').map(u => u.user);


  return (

    <div className="flex flex-row">
      <div className="flex flex-row pr-1">
        <ProjectManagerList projectManagers={projectManagers} />
      </div>- {dateStr}
    </div>
  )
}

function ProjectManagerList(props: { projectManagers: User[] }) {
  const pms = props.projectManagers;
  const len = pms.length;

  return (
    <div>
      {pms.map((pm, i) =>
        <span key={pm.id}>
          <LinkToUser key={pm.id} user={pm}/>
          {i !== len-1 && (<span className="pr-1">,</span>)}
        </span>
      )}
    </div>
  )
}

function StaffingSuggestionCardTitle(props: { project: Project }) {
  return (
    <LinkToProject project={props.project}/>
  )
}

function StaffingSuggestionLineInfo(props: { user: User, suggestion: StaffingSuggestion }) {
  let decisionText = "Open";
  const s =props.suggestion;
  if (s.status === "accepted") {
    decisionText = "Accepted";
  }
  if (s.status === "rejected") {
    decisionText = "Rejected";
  }

  const decisionUserId = props.suggestion.decider_id;
  const decisionTimeStr = formatFriendlyDateWithoutYear(s.decided_at);
 
  return (
    <div>
      <LinkToUser user={props.user}/><br/>
      {s.status !== "new" && (
        <>
          {decisionText} by <LinkToUserById id={decisionUserId}/> on {decisionTimeStr}
        </>
      )}
    </div>
  )
}

type StaffingSuggestionState = "new" | "accepted" | "rejected";
const suggestionStates = [
  { label: "Open", value: "new" },
  { label: "Accepted", value: "accepted" },
  { label: "Rejected", value: "rejected" }
];

export function StaffingSuggestionsIndex() {
  const { api, roles } = useAuth();

  if (!canViewAllSuggestions(roles)) {
    return <Forbidden />;
  }

  const [suggestionFilter, setSuggestionFilter] = useState<StaffingSuggestionState>("new");

  const { isPending, isError, data } = api.fetchOpenStaffingSuggestions();

  if (isPending) {
    return <Loading />;
  }

  if (isError) {
    return <InternalError />;
  }

  const filteredSuggestions = data.filter(s => s.staffing_suggestion.status === suggestionFilter).slice(0, 75);

  return (
    <>
      <div>
        <MainTitle
          text="Staffing Suggestions"
          extra={
            <Select className="w-40" options={suggestionStates} value={suggestionFilter} onChange={(v) => setSuggestionFilter(v)}/>
          }
        />
      </div>

      <div className="flex flex-col gap-2">
        {filteredSuggestions.map((s) => <SingleStaffingSuggestion data={s} key={s.staffing_suggestion.id} />)}
      </div>
    </>
  );
}

function buildTimelineFromSuggestions(suggestions: StaffingSuggestionEntry[]) : Timeline {
  const today = new Date();

  let start = null as Date|null;
  let end = null as Date|null;

  (suggestions ?? []).forEach(s => {
    const d = parseDate(s.date);
    if (start === null || d < start) {
      start = d;
    }

    if (end === null || d > end) {
      end = d;
    }
  });

  if (end == null) {
    end = today;
  }
  if (start == null) {
    start = today;
  }

  return {
    view: {
      start: start,
      end: end,
    },
    project: {
      start: start,
      end: end,
    },
    today: today
  };
}
