import { dateSpanToQueryParam } from "../../utils";
import { sortProjectUsersByGivenName } from "../../utils/sort";
import {
  BookingLine,
  Bookings,
  BookingsHeader,
  BookingTable,
} from "../Bookings/Bookings";

import {
  BookingKind,
  BookingMode,
  BookingOptions,
  Project,
  Timeline,
  User,
  UserAndProjectUser,
} from "../../models";
import { useAuth } from "../../Providers/AuthProvider";
import { UserBookingName } from "../Generic/UserBookingName";
import { Popover } from "antd";
import { CircleHelpIcon } from "lucide-react";

interface ProjectBookingProps {
  project: Project;
  projectUsersWithUsers: Array<UserAndProjectUser>;
  onRemoveUser: (user: User, projectUserId: number) => void;
  displayMode: BookingMode;
  timeline: Timeline;
  kind?: BookingKind;
  cacheInvalidator?: number;
  visibleKinds?: BookingKind[];
}

interface ProjectBookingLineProps extends ProjectBookingProps {
  userAndProjectUser: UserAndProjectUser;
  loadTime?: Date;
}

function WhatIsFTEIcon() {
  const content = (
    <div>
      A FTE (Full Time Employee) is considered someone who works 33hrs/week.
    </div>
  )
  return (
    <Popover className="cursor-pointer" content={content} title="What is a FTE?">
      <CircleHelpIcon size="18px"/>
    </Popover>
  )
}

function ProjectBookingSummaryLine(props: ProjectBookingProps) {
  const { api } = useAuth();
  const params = dateSpanToQueryParam(props.timeline.view);
  const projectId = props.project.id.toString();
  const fetchBookings = api.fetchProjectBookingsByProjectId(projectId, params);

  if (fetchBookings.isPending) {
    return (
      <tr>
        <td>loading</td>
      </tr>
    );
  }

  if (fetchBookings.isError) {
    return (
      <tr>
        <td>error</td>
      </tr>
    );
  }

  if (props.displayMode === BookingMode.Edit) {
    return null;
  }

  const options = {
    project: props.project,
    bookings: fetchBookings.data,
    timeline: props.timeline,
    mode: BookingMode.View,
    summary: false,
    showPopover: false,
    kind: props.kind,
    visibleKinds: props.visibleKinds,
    maxHours: 10000,
  } as BookingOptions;

  return (
    <>
    <BookingLine
      mode={props.displayMode}
      lineContent={
        <div className="justify-center">
          <b>Summary</b>
        </div>
      }>
      <Bookings options={options} />
    </BookingLine>
    <BookingLine
      mode={props.displayMode}
      lineContent={
        <div className="flex flex-row items-center justify-center gap-2 align-middle">
          <b>Summary FTE</b> <WhatIsFTEIcon/>
        </div>
        }
    >
      <Bookings options={{showFTE: true, ...options}} />
    </BookingLine>
    </>
  );
}

function ProjectBookingLine(props: ProjectBookingLineProps) {
  const { api } = useAuth();
  const params = dateSpanToQueryParam(props.timeline.view);
  params.timestamp = props.loadTime;

  const { user, project_user } = props.userAndProjectUser;
  const userId = user.id.toString();

  const fetchAbsences = api.fetchAbsencesByUserId(userId, params);
  const fetchSchedule = api.fetchUserWorkDayScheduleByUserId(userId);
  const fetchBookings = api.fetchProjectBookingsByUserId(userId, params);
  const fetchHolidays = api.fetchHolidaysByLocationId(
    user.location_id ?? 0,
    params,
  );

  if (
    fetchBookings.isPending ||
    fetchAbsences.isPending ||
    fetchHolidays.isLoading ||
    fetchSchedule.isPending
  ) {
    return (
      <tr>
        <td>loading</td>
      </tr>
    );
  }

  if (
    fetchBookings.isError ||
    fetchAbsences.isError ||
    fetchHolidays.isError ||
    fetchSchedule.isError
  ) {
    return (
      <tr>
        <td>error</td>
      </tr>
    );
  }

  const options = {
    userAndProjectUser: props.userAndProjectUser,
    project: props.project,
    bookings: fetchBookings.data,
    absences: fetchAbsences.data,
    holidays: fetchHolidays.data,
    timeline: props.timeline,
    schedules: fetchSchedule.data,
    mode: props.displayMode,
    showPopover: true,
    kind: props.kind,
    visibleKinds: props.visibleKinds,
    onChange: api.setAllocation,
  } as BookingOptions;

  const removeUser = () => props.onRemoveUser(user, project_user.id);

  // todo: come here user unneccessary
  const popover = (
    <UserPopover
      userAndProjectUser={props.userAndProjectUser}
      mode={props.displayMode}
      removeUser={removeUser}
    />
  );

  return (
    <BookingLine
      mode={props.displayMode}
      key={project_user.id}
      lineContent={popover}
    >
      <Bookings options={options} />
    </BookingLine>
  );
}

function ProjectBookings(props: ProjectBookingProps) {
  const timeline = props.timeline;
  const sortedUsers = sortProjectUsersByGivenName(props.projectUsersWithUsers);
  const loadTime = new Date();

  return (
    <BookingTable width="300px">
      <BookingLine>
        <BookingsHeader timeline={timeline} />
      </BookingLine>

      {sortedUsers.map((pu) => (
        <ProjectBookingLine
          key={pu.project_user.id}
          {...props}
          userAndProjectUser={pu}
          loadTime={loadTime}
        />
      ))}
      <ProjectBookingSummaryLine {...props} />
    </BookingTable>
  );
}

interface UserPopoverProps {
  userAndProjectUser: UserAndProjectUser;
  mode: BookingMode;
  removeUser: () => void;
}
function UserPopover(props: UserPopoverProps) {
  const mode = props.mode;
  const user = props.userAndProjectUser.user;
  const projectUser = props.userAndProjectUser.project_user;

  if (mode === BookingMode.Edit) {
    return (
      <div className="flex w-full cursor-pointer items-center justify-between">
        <UserBookingName user={user} subText={projectUser.role} />
      </div>
    );
  }

  return <UserBookingName user={user} subText={projectUser.role} />;
}

export default ProjectBookings;
