import { useAuth } from "../../Providers/AuthProvider";
import {
  dateRangeToQueryParam,
  formatFriendlyDateWithoutYear,
} from "../../utils";

interface HolidaysProps {
  locationId: number | null;
  start: Date;
  end: Date;
}

export function Holidays(props: HolidaysProps) {
  const { api } = useAuth();

  if (props.locationId == null) {
    return <div>No Location Set</div>;
  }

  const params = dateRangeToQueryParam(props.start, props.end);
  const fetchHolidays = api.fetchHolidaysByLocationId(props.locationId, params);

  if (fetchHolidays.isPending) {
    return <div>loading</div>;
  }

  if (fetchHolidays.isError) {
    return <div>error</div>;
  }

  const holidays = fetchHolidays.data;

  return (
    <ul>
      {holidays.map((h) => {
        return (
          <li key={h.id}>
            🗓 {formatFriendlyDateWithoutYear(h.date)} - {h.name}
          </li>
        );
      })}
    </ul>
  );
}
