import { UseMutationResult } from "@tanstack/react-query";
import {
  Allocation,
  BookingMode,
  BookingOptions,
  WeekMetadata,
  WeekSummary,
} from "../../models";
import { WeekInfo, weeksBetweenDates } from "../../utils";
import { BookingCellEdit } from "./EditCell";
import {
  buildWeekMetadata,
  buildWeekSummaries,
  collectBookingsByWeek,
  weekKey,
} from "./logic";
import { BookingLine, BookingsHeader, BookingTable } from "./Structure";
import { BookingCellView } from "./ViewCell";
export { BookingLine, BookingsHeader, BookingTable };

interface BookingsProps {
  options: BookingOptions;
}

export function Bookings(props: BookingsProps) {
  const options = props.options;
  const bookings = options.bookings;
  const absences = options.absences;
  const holidays = options.holidays;
  const countAbsence = options.countAbsence ?? false;
  const timeline = options.timeline;
  const maxHours = options.maxHours ?? 0;

  const projectId = options.project ? options.project.id : undefined;
  const visibleKinds = options.visibleKinds ? options.visibleKinds : [];

  options.mode = options.mode ?? BookingMode.View;

  const weeks = weeksBetweenDates(timeline.view.start, timeline.view.end);
  const weekSummaries = buildWeekSummaries(
    projectId,
    bookings,
    countAbsence,
    holidays,
    absences,
    maxHours,
    visibleKinds,
    options.schedules,
  );
  const bookingsByWeek = collectBookingsByWeek(weeks, weekSummaries);
  const weekMetadata = buildWeekMetadata(weeks, timeline);

  return (
    <>
      {weeks.map((w) => {
        const k = weekKey(w);
        const ws = bookingsByWeek.get(k);
        const md = weekMetadata.get(k);

        if (ws === undefined) {
          throw Error("missing week summary");
        }

        if (md === undefined) {
          throw Error("missing week metadata");
        }

        return (
          <BookingCell
            key={`bc-${w.year}-${w.week}`}
            week={w}
            options={options}
            weekMetadata={md}
            weekSummary={ws}
          />
        );
      })}
    </>
  );
}

export interface BookingCellProps {
  weekSummary: WeekSummary;
  weekMetadata: WeekMetadata;
  week: WeekInfo;
  options: BookingOptions;
  mutation?: UseMutationResult<Allocation, Error, Allocation>;
}

function BookingCell(props: BookingCellProps) {
  const weekMetadata = props.weekMetadata;
  const options = props.options;
  const mode = options.mode;

  if (mode === BookingMode.View) {
    return <BookingCellView {...props} />;
  }

  if (mode === BookingMode.Edit) {
    if (weekMetadata.beforeCurrentWeek) {
      return <BookingCellView {...props} />;
    }

    if (!weekMetadata.projectRunning && weekMetadata.beforeProject) {
      return <BookingCellEdit {...props} />;
    }

    if (!weekMetadata.projectRunning) {
      return <BookingCellView {...props} />;
    }

    return <BookingCellEdit {...props} />;
  }

  return <div>Shouldnt get here</div>;
}
