import { ProjectPlanRecord } from "@/models";
import { formatDate } from "@/utils";
import { DateString, PlannerRoleName, ProjectPlan, Role } from "./types";

export function dateToDateString(d: Date | string): DateString {
  return formatDate(d);
}

export function buildProjectPlan(
  months: Date[],
  records: ProjectPlanRecord[],
): ProjectPlan {
  // role -> project_id -> date(week) amount
  const map = new Map<PlannerRoleName, Map<number, Map<DateString, number>>>();

  for (const r of records) {
    let roleMap = map.get(r.role);

    if (roleMap === undefined) {
      roleMap = new Map<number, Map<DateString, number>>();
    }

    let projectMap = roleMap.get(r.project_id);
    if (projectMap === undefined) {
      projectMap = new Map<DateString, number>();

      months.forEach((m) => {
        projectMap!.set(dateToDateString(m), 0);
      });

      roleMap.set(r.project_id, projectMap);
    }

    projectMap.set(r.month, Number(r.amount));

    map.set(r.role, roleMap);
  }

  return map;
}

export const allRoles: Role[] = [
  { label: "Business Intelligence", value: "Business Intelligence" },
  { label: "Commerce Cloud", value: "Commerce Cloud" },
  { label: "Data Cloud", value: "Data Cloud" },
  { label: "Development", value: "Development" },
  { label: "Experience Cloud", value: "Experience Cloud" },
  { label: "Field Service Lightning", value: "Field Service Lightning" },
  { label: "Integration", value: "Integration" },
  { label: "Marketing Cloud AE", value: "Marketing Cloud AE" },
  { label: "Marketing Cloud Engagement", value: "Marketing Cloud Engagement" },
  { label: "Marketing Cloud Growth", value: "Marketing Cloud Growth" },
  { label: "Media Cloud", value: "Media Cloud" },
  { label: "NGO", value: "NGO" },
  { label: "Omni / Industries", value: "Omni / Industries" },
  { label: "Project Management", value: "Project Management" },
  { label: "Revenue Cloud", value: "Revenue Cloud" },
  { label: "Sales Cloud", value: "Sales Cloud" },
  { label: "Service Cloud", value: "Service Cloud" },
];

export function buildRoles(records: ProjectPlanRecord[]): PlannerRoleName[] {
  const s = new Set<PlannerRoleName>();

  for (const r of records) {
    s.add(r.role);
  }

  return [...s.values()];
}

export function summarizeMap(m: Map<number, Map<string, number>>): number {
  return m.values().reduce((t, v) => t + v.values().reduce((t, v) => t + v), 0);
}

export const isValidNumber = (str: string): boolean => {
  const test = !isNaN(Number(str)) && str.trim() !== "";
  const test2 = /^\d*\.?\d+$/.test(str);
  console.log("test num", str, "test", test, test2);
  return test2;
};

export const removeLeadingZeros = (str: string): string => {
  // Handle negative numbers
  if (str.startsWith("-")) {
    return "-" + removeLeadingZeros(str.slice(1));
  }

  // Handle decimals
  if (str.includes(".")) {
    const [whole, decimal] = str.split(".");
    return `${Number(whole)}.${decimal}`;
  }

  const v = Number(str);
  if (Number.isNaN(v)) {
    return "0";
  }

  return String(v);
};

export function sumForecast(projectPlan: ProjectPlan): number {
  let sum = 0;

  for (const [_, projectMap] of projectPlan.entries()) {
    for (const [_, monthMap] of projectMap.entries()) {
      for (const [_, amount] of monthMap.entries()) {
        sum += amount;
      }
    }
  }

  return sum;
}
